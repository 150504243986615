.nav {
    list-style-type: none;
    padding: 0;
  }
  .nav li{
    margin-bottom: 2px !important;
    margin-left: 10px;
  }
  .nav .drop-btn {
    padding-top: 10px;
  }
  
  .nav .drop-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #A6ADB8;
    text-decoration: none;
  }
  
  .nav .drop-menu:hover {
    background-color: #444;
  }
  
  .nav .collapse {
    padding-left: 20px;
  }
  
  .nav .card-body {
    padding: 10px !important;
  }
  
  .nav .card-body .nav-link {
    color: #A6ADB8;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .nav .card-body .nav-link:hover {
    background-color: #555;
  }
  
  .nav .active {
    background-color: none;
    color: #000;
  }
  
  .org-icon {
    margin-right: 10px;
  }
 .form-sgsurvey{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 40px;
 }