.header-top{
    background-color: #01A9FF;
    padding-top: 10px;
    padding-bottom: 10px;
}
.header-home{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo{
   width: 90px;
   height: 60px;
}
.footer{
    background-color: #01A9FF;
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer-copy{
    text-align: center;
}
.form-bg{
    background-color: aliceblue;
    padding: 30px;
}
.sur-list{
    color: #fff;
}

 .footer{
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
 }
 .copyright-link{
    color: #fff;
 }
 .gpsbutton{
    padding: 0px 10px !important;
 }