body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  
  }
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
  a{
    text-decoration: none !important;
  }
  .sign-bg {
    background-image: url("../img/firstsgb.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  .sign-f {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -25%);
    z-index: 1;
    text-align: center;
  }
  .surveyer-login-bg{
    background-image: url("../img/user-login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  .login-bg {
    background-image: url("../img/digiatal-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  .sign-h {
    color: #000;
    padding-bottom: 5px;
  }
  
  .form-sg {
    background: radial-gradient(circle, #FF9933, #FFFFFF, #138808);
    border-radius: 5px;
    padding: 40px;
  }
  
  .sign-input {
    padding: 8px;
    width: 300px;
    border: 1px solid #000;
    border-radius: 5px;
    outline: none;
  }
  
  .sign-p {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #CC3333;
    text-decoration: none;
  }
  
  .sign-b {
    padding: 8px;
    width: 300px;
    background-color: #3399FE;
    border-radius: 5px;
    border: 1px solid #3399FE;
    color: #fff;
  }
  
  .forg-p {
    font-size: 14px;
    padding-top: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-style: italic;
    display: flex;
    justify-content: flex-end;
  }
  
  /* profile update form */
  .update-form {
    margin: 100px auto;
  }
  
  .input-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
  }
  
  .input-container {
    position: relative;
  }
  
  .input-container input:focus {
    outline: none;
  }
  
  .input-container label {
    position: absolute;
    top: -30px;
    left: 0;
    background-color: #f9f9f9;
    padding: 5px;
  }
  
  .input-container input {
    padding: 5px;
    border: none;
    border-bottom: 1px solid #000;
    width: 350px;
  }
  
  .edit-btn {
    padding: 8px 40px;
    border: #fff;
    color: #fff;
    background-color: #CC3333;
  }
  
  
  /* forgot */
  .sign-forgot {
    background-image: url("../img/firstsgb.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  /* Dashboard */
  .App {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  
  .main-container {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  
  /* .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background-color: #fff;
    margin-left: 15px;
  } */
   .main-content{
    padding-left: 10px;
    padding-right: 10px;
   }
  
  /* Sidebar.css */
  .sidebar {
    width: 220px;
    height: 85vh;
    transition: width 0.3s;
    background-color: #3C4B64;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }
  .main-secd{
    padding: 0px;
    margin: 0px;
  }
  .main-secr{
    overflow: scroll;
    height: 88vh;
  }
  .side-secd{
    padding: 0px;
  }
  .sidebar.collapsed {
    width: 0px !important;
  }
  
  
  .collapse-button {
    margin-bottom: 40px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #333;
    position: absolute;
    top: 10px;
    left: 0px;
  }
  
  .collapse-button:hover {
    color: blue;
  }
  
  .nav {
    list-style: none;
    padding: 0;
    flex-grow: 1;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
  }
  .without-nav{
    margin-left: 12px;
    color: #A6ADB8;
    cursor: pointer;
  }
  
  /* .nav li {
    margin-bottom: 18px;
    cursor: pointer;
    color: #A6ADB8;
    padding-left: 20px;
  }
  
  .nav li:hover {
    color: #000;
  } */
  
  .sidebar-toggle-button {
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s;
  }
  
  .sidebar-toggle-button:hover {
    background-color: #ccc;
  }
  
  .pro-box {
    display: flex !important;
    justify-content: flex-end;
    padding-top: 7px;
  }
  
  .pro-pic {
    width: 40px;
    height: 40px;
    border: 1px solid #0CBAA5;
    padding: 4px;
    border-radius: 50px;
    margin-right: 20px;
  }
  
  .noti-icon {
    height: 25px;
    width: 25px;
    margin-right: 30px;
    margin-top: 7px;
  }
  
  
  .profile {
    margin-left: 200px;
  }
  
  
  /* Enquires */
  .table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .trh {
    font-weight: 700;
    font-size: 16px;
  }
  
  th {
    background-color: #3C4B64 !important;
    color: #A6ADB8 !important;
  }
  
  @media (max-width: 600px) {
    table {
      border: none;
    }
  
    table thead {
      display: none;
    }
  
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }
  
    table tr {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  
    table td {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      border: none;
    }
  
    table td::before {
      content: attr(data-label);
      flex-basis: 40%;
      font-weight: bold;
      white-space: nowrap;
    }
  
    table td {
      flex-basis: 60%;
      text-align: left;
    }
  }
  
  .table-b {
    border-bottom: 1px solid #c6c3c3;
  }
  
  .add-btn {
    border: 1px solid #CC3333;
    border-radius: 5px;
  }
  /* Header.css */
  .header {
    background-color: #f0f0f0;
    padding: 10px 20px;
  }
  
  .header-toggle-button {
    background-color: #3399FE;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.3s;
  }
  
  .header-toggle-button:hover {
    background-color: #000;
  }
  
  .dash-title {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    padding: 10px;
  }
  
  .add-header {
    padding: 10px 10px;
    background-color: #c6c3c3;
  }
  
  .dash-input {
    padding: 3px;
    width: 200px !important;
    border: 1px solid #3399FE;
  }
  
  .sdash-icon {
    background-color: #3399FE;
    padding: 3px 8px;
    border: 1px solid #3399FE;
    color: #fff;
    margin-left: 5px;
  }
  
  .dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    position: absolute;
    top: 125%;
    right: 10px;
    width: 170px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
  }
  
  .dropdown-content li {
    list-style: none;
    padding: 2px;
    font-size: 15px;
    font-weight: 400;
  }
  
  .dropdown-content li a {
    color: #000;
    text-decoration: none;
  }
  
  .dropdown-content ul {
    padding: 0px;
  }
  
  img {
    cursor: pointer;
  }
  
  
  .edi-btn {
    background-color: #0CBAA5;
    padding: 2px 15px;
    border-radius: 10px;
    border: 1px solid #0CBAA5;
    margin: 5px;
    color: #fff;
  }
  
  .tr-sec {
    background-color: #f0f0f0;
  }
  
  
  @media only screen and (max-width: 1200px) {
    .dash-title {
      display: none;
    }
  
    .box-h1 {
      width: 20% !important;
    }
  
    .box-h2 {
      display: none;
    }
  
    .pro-box {
      /* display: flex !important; */
      justify-content: flex-end;
      padding-top: 7px;
    }
  }
  
  .th-bg {
    background-color: #0CBAA5;
    color: #fff;
  }
  
  .tr-bg {
    background-color: #ccedea;
  }
  
  .org-icon {
    width: 20px;
    height: 20px;
    /* margin-bottom: 5px; */
  }
  
  .org-tit {
    font-size: 18px;
    padding-top: 10px;
    padding-left: 5px;
  }