.qr-btn {
  padding: 3px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.error {
  color: red;
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 10px;
  text-align: center;
}

.qr-plate {
  position: relative;
  width: 1800px; /* Set width to 576px */
  height: 900px; /* Set height to 288px */
}

.plate-image {
  width: 100%;
  height: 100%;
}

.qr-code-container {
  position: absolute;
  top: 60%;
  left: 11.3%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 405px;
  height: 405px;
  flex-direction: column;
  align-items: center;
}

.qr-id {
  position: absolute;
  bottom: 10px; /* Adjust the position as needed */
  width: 100%;
  text-align: center;
  color: black;
  font-size: 36px; /* Adjust font size if needed */
}

.mp-text {
  position: absolute;
  bottom: 37px;
  right: 755px;
  /* font-size: 37px; */
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #007bff;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: none;
}

.pagination button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.pagination li {
  margin: 0 0.25rem;
  cursor: pointer;
}

.pagination li a {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  color: #007bff;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination li.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: not-allowed;
}